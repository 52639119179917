import { useMemo } from 'react';
import { z, type ZodSchema } from 'zod';

import { useGetProjectQuery } from 'api/projects';

import { useErrorLogger } from 'hooks/use_error_logger';
import {
  COMPENSATION_TYPE_OTHER,
  THEY_PAY_COMPENSATION_OPTION_VALUES,
  THEY_PAY_COMPENSATION_IN_PERSON_OPTION_VALUES,
} from 'researcher/project_workspace/constants';

import { PROJECT_SOURCE_TYPES } from 'lib/generated_constants/project_source_types';
import {
  CompensationMethods,
  IntegrationProviders,
  ProjectSettings,
  StudyTypes,
  InterviewTypes,
  ProjectStatuses,
} from 'lib/generated_constants/projects';

import { useProjectWorkspaceContext } from './use_project_workspace_context';

const computeIsAutopayEnabled = (projectSettings: unknown) => {
  if (!projectSettings || typeof projectSettings !== 'object') return false;
  if (!('disableAutopay' in projectSettings)) return false;

  if (typeof projectSettings.disableAutopay === 'boolean') return projectSettings.disableAutopay === false;
  if (typeof projectSettings.disableAutopay === 'string') return projectSettings.disableAutopay === 'false';

  return false;
};

const projectSchema = z.object({
  id: z.string(),
  accessCode: z.string(),
  additionalRecruitmentRequirementsHtml: z.string().nullable(),
  advancedScreening: z.boolean(),
  billingAccount: z.object({
    id: z.string(),
    commenterId: z.string(),
    initials: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    fullName: z.string(),
    lookbackEnabledAt: z.string().nullable(),
    name: z.string(),
    nameLastFirst: z.string(),
    messagerId: z.number(),
    roleId: z.number().nullable().optional(),
    phoneNumber: z.string().nullable(),
    verifiedTeam: z.object({ id: z.string() }).optional().nullable(),
  }),
  compensationAmount: z.string().nullable(),
  compensationMethodId: z.number().nullable(),
  compensationType: z.string().nullable(),
  confirmationNotesHtml: z.string().nullable(),
  consumerFee: z.number(),
  createdAt: z.string(),
  currentStatus: z.string(),
  defaultSessionLocation: z.string().nullable(),
  deviceRequirements: z.object({
    summary: z.array(z.array(z.string())),
    ids: z.array(z.number()),
  }).nullable(),
  documentDescription: z.object({
    description: z.string().nullable(),
    signatureRequestDataKey: z.string().nullable(),
  }),
  documentName: z.string().optional(),
  draftParticipantsCount: z.number(),
  emailProfileId: z.number().nullable(),
  emails: z.array(z.object({
    allowEdit: z.boolean(),
    class: z.object({}),
    description: z.string(),
    id: z.number().nullable(),
    purpose: z.string(),
    name: z.string(),
    slug: z.string(),
  })),
  emailThemeId: z.number().nullable(),
  hasDocumentSigning: z.boolean(),
  incentive: z.string(),
  internalDescription: z.string().nullable(),
  interviewType: z.string().nullable(),
  interviewTypeId: z.number().nullable(),
  isActive: z.boolean(),
  isEligibleForSessionObservers: z.boolean().nullable(),
  isPrivate: z.boolean(),
  isSystemHidden: z.boolean(),
  isZeroCost: z.boolean(),
  latestStatusTypeId: z.number(),
  location: z.object({
    address: z.string().nullable().optional(),
    autocomplete: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    countries: z.array(z.string()).optional(),
    detail: z.string().nullable().optional(),
    // TODO: Get a working type for this column; afaict it is indeed an array of objects. For some reason though, when
    // specifying the type as z.array(z.object({})), the data is not being coerced into an empty object making users unable
    // to see their City selections in the UI
    // googleLocationInfo: z.array(z.object({})).optional(),
    googleLocationInfo: z.any(),
    googleMapsUrl: z.string().nullable().optional(),
    latitude: z.number().nullable().optional().optional(),
    locationExtraInfo: z.string().nullable().optional(),
    longitude: z.number().nullable().optional().optional(),
    parkingAvailable: z.boolean().optional(),
    parkingInfo: z.string().nullable().optional(),
    regions: z.array(z.string()).optional(),
  }).nullable(),
  meta: z.object({
    canView: z.boolean(),
    canEdit: z.boolean(),
    invalidLaunchMessages: z.string().optional(),
    invalidLaunchProps: z.array(z.string()).optional(),
    firstProjectId: z.string().optional(),
  }),
  name: z.string().nullable(),
  numParticipants: z.number().nullable(),
  organizationId: z.number(),
  originalNumParticipants: z.number().nullable(),
  owner: z.object({
    id: z.string(),
    commenterId: z.string(),
    initials: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    fullName: z.string(),
    lookbackEnabledAt: z.string().nullable(),
    name: z.string(),
    nameLastFirst: z.string(),
    messagerId: z.number(),
    roleId: z.number().nullable().optional(),
    phoneNumber: z.string().nullable(),
    verifiedTeam: z.object({ id: z.string() }).optional().nullable(),
    relationshipNames: z.array(z.string()).optional(),
  }),
  participantPopulationId: z.number().nullable(),
  participantReferrals: z.boolean(),
  professionalFee: z.number(),
  projectCustomEmailSetId: z.number().nullable(),
  projectStatus: z.string(),
  publicDescription: z.string().nullable(),
  publicTitle: z.string().nullable(),
  remainingInviteCount: z.number().optional(),
  requireApproval: z.boolean().optional(),
  researcherWillCall: z.boolean(),
  screenerVideo: z.object({
    id: z.string(),
    prompt: z.string(),
  }).nullable(),
  sessionLength: z.string().nullable(),
  settings: z.object({
    // TODO: There are many more settings; their types are defined by the ProjectSetting BobEnum
    requireApproval: z.boolean().optional(),
    participantReschedulingEnabled: z.boolean().optional(),
  }),
  sourceType: z.string(),
  studyLength: z.object({
    days: z.number().optional(),
    hours: z.number().optional(),
    minutes: z.number().optional(),

  }),
  studyType: z.string(),
  studyTypeId: z.number().nullable(),
  studyTypeName: z.string(),
  surveyDraft: z.object({
    id: z.string(),
    surveyJson: z.string(),
  }).nullable(),
  targetProfessionals: z.boolean(),
  task: z.object({
    id: z.string(),
    externalId: z.string().nullable(),
    provider: z.string(),
    repositoryUrl: z.string().nullable(),
    providerDisplayName: z.string().nullable(),
    title: z.string().nullable(),
    url: z.string().nullable(),
  }).nullable(),
  taskSession: z.object({
    id: z.string(),
    moderatorId: z.number(),
    numScheduled: z.number(),
    numSlots: z.number(),
    sessionLocation: z.string().nullable(),
    startTime: z.string(),
    datetimeDisplay: z.string(),
    hasPassed: z.boolean(),
    hostUrl: z.string().nullable(),
    hasZoomSessionLocation: z.boolean(),
    hasMeetSessionLocation: z.boolean(),
    start: z.string(),
    startDate: z.string(),
    timeDisplay: z.string(),
  }).nullable(),
  teamDomain: z.string().nullable(),
  teamId: z.number(),
  timezone: z.string().nullable(),
  useEmailTheme: z.boolean(),
  wePay: z.boolean().nullable(),
}).passthrough().optional();

export function buildProjectState(project: z.infer<typeof projectSchema>) {
  if (!project) return undefined;

  const {
    accessCode,
    additionalRecruitmentRequirementsHtml,
    advancedScreening,
    billingAccount,
    compensationAmount,
    compensationMethodId,
    compensationType,
    confirmationNotesHtml,
    consumerFee,
    defaultSessionLocation,
    deviceRequirements,
    documentDescription,
    documentName,
    draftParticipantsCount,
    emailProfileId,
    emailThemeId,
    emails,
    hasDocumentSigning,
    id,
    internalDescription,
    interviewTypeId,
    isActive,
    isEligibleForSessionObservers,
    isPrivate,
    isSystemHidden,
    isZeroCost,
    latestStatusTypeId,
    location,
    meta: { invalidLaunchMessages, invalidLaunchProps, firstProjectId } = {},
    name,
    numParticipants,
    organizationId,
    originalNumParticipants,
    owner,
    participantPopulationId,
    participantReferrals,
    professionalFee,
    projectCustomEmailSetId,
    publicDescription,
    publicTitle,
    remainingInviteCount,
    requireApproval,
    researcherWillCall,
    screenerVideo,
    sourceType,
    studyLength,
    studyTypeId,
    surveyDraft,
    targetProfessionals,
    task,
    taskSession,
    teamDomain,
    teamId,
    timezone,
    useEmailTheme,
  } = project;

  const isAutopayEnabled = computeIsAutopayEnabled(project.settings);
  const isNoIncentiveTypeProject = compensationMethodId === CompensationMethods.NONE;
  const isWePay = compensationMethodId === CompensationMethods.WE_PAY;
  const senderId = project.owner?.messagerId;
  const hasIntegration = task?.provider && IntegrationProviders.TRACKED_PROVIDERS.includes(task.provider);
  const isParticipantApprovalRequired =
    project.settings?.requireApproval ?? ProjectSettings.requireApproval.default;
  const isRecruitProject = sourceType === PROJECT_SOURCE_TYPES.PROJECT;
  const isHubProject = sourceType === PROJECT_SOURCE_TYPES.BYOA_PROJECT;
  const isSourcelessProject = sourceType === PROJECT_SOURCE_TYPES.SOURCELESS_PROJECT;
  const hasParticipantSource = !isSourcelessProject;
  const isOneOnOne = studyTypeId === StudyTypes.ONE_ON_ONE;
  const isFocusGroup = studyTypeId === StudyTypes.FOCUS_GROUP;
  const isMultiDay = studyTypeId === StudyTypes.MULTI_DAY;
  const isUnmoderatedTask = studyTypeId === StudyTypes.UNMODERATED_TASK;
  const hasStudyType = studyTypeId && Object.values(StudyTypes).includes(studyTypeId);
  const isModerated = hasStudyType && !isUnmoderatedTask;
  const isOnlineInterview = interviewTypeId === InterviewTypes.ONLINE;
  const isPhoneInterview = interviewTypeId === InterviewTypes.PHONE;
  const isInPersonInterview = interviewTypeId === InterviewTypes.IN_PERSON;
  const isDraft = latestStatusTypeId === ProjectStatuses.DRAFT;
  const isLaunchable = !invalidLaunchProps?.length;
  const isLaunched = latestStatusTypeId === ProjectStatuses.LAUNCHED;
  const participantReschedulingEnabled = project.settings?.participantReschedulingEnabled;
  const isFirstProject = String(firstProjectId) === String(id);

  const hasOtherCompensationType = !!compensationType &&
    (isInPersonInterview ?
      !THEY_PAY_COMPENSATION_IN_PERSON_OPTION_VALUES.includes(compensationType) :
      !THEY_PAY_COMPENSATION_OPTION_VALUES.includes(compensationType));
  const derivedCompensationType = hasOtherCompensationType ? COMPENSATION_TYPE_OTHER : compensationType;
  const compensationTypeOtherCustomValue = hasOtherCompensationType ? compensationType : undefined;

  return {
    accessCode,
    additionalRecruitmentRequirementsHtml,
    advancedScreening,
    billingAccount,
    compensationAmount,
    compensationMethodId,
    compensationType: derivedCompensationType,
    compensationTypeOtherCustomValue,
    confirmationNotesHtml,
    consumerFee,
    defaultSessionLocation,
    deviceRequirements,
    documentDescription,
    documentName,
    draftParticipantsCount,
    emailProfileId,
    emailThemeId,
    emails,
    hasDocumentSigning,
    hasIntegration,
    hasParticipantSource,
    hasStudyType,
    id,
    internalDescription,
    interviewTypeId,
    invalidLaunchMessages,
    invalidLaunchProps,
    isActive,
    isAutopayEnabled,
    isDraft,
    isEligibleForSessionObservers,
    isFirstProject,
    isFocusGroup,
    isHubProject,
    isInPersonInterview,
    isLaunchable,
    isLaunched,
    isModerated,
    isMultiDay,
    isNoIncentiveTypeProject,
    isOneOnOne,
    isOnlineInterview,
    isParticipantApprovalRequired,
    isPhoneInterview,
    isPrivate,
    isRecruitProject,
    isSourcelessProject,
    isSystemHidden,
    isUnmoderatedTask,
    isWePay,
    isZeroCost,
    latestStatusTypeId,
    location,
    name,
    numParticipants,
    organizationId,
    originalNumParticipants,
    owner,
    participantPopulationId,
    participantReferrals,
    participantReschedulingEnabled,
    professionalFee,
    projectCustomEmailSetId,
    publicDescription,
    publicTitle,
    remainingInviteCount,
    requireApproval,
    researcherWillCall,
    screenerVideo,
    senderId,
    sourceType,
    studyLength,
    studyTypeId,
    surveyDraft,
    targetProfessionals,
    task,
    taskSession,
    teamDomain,
    teamId,
    timezone,
    useEmailTheme,
  };
}

export type ProjectState = ReturnType<typeof buildProjectState>;

function safelyParseSchema<T>(
  data: unknown,
  schema: ZodSchema<T>,
  callback: (error: Error) => void,
) {
  if (['development', 'test'].includes(process.env.NODE_ENV ?? '')) {
    return schema.parse(data);
  }

  try {
    return schema.parse(data);
  } catch (error) {
    callback(error as Error);

    return data as T;
  }
}

// use this hook to pull project state instead of the rtk query directly.
// this allows us to deserialize the data into a more usable format
// and prevents a bunch of potential fallout if/when we rework the project
// model for "continuous research."
export function useGetProjectState() {
  const logError = useErrorLogger();
  const { projectId } = useProjectWorkspaceContext();
  const { data, ...rest } = useGetProjectQuery({
    projectId,
    include: [
      'billing_account',
      'default_session_location',
      'document_description',
      'document_name',
      'draft_participants_count',
      'emails',
      'screener_video',
      'survey_draft',
      'task',
      'task_session',
    ].join(','),
  });

  const schema = useMemo(() => projectSchema, []);

  const projectState = useMemo(() => {
    if (!data) return undefined;
    const project = safelyParseSchema(data, schema, (error) => {
      logError(error);
    });

    return buildProjectState(project);
  }, [data, logError, schema]);

  return {
    ...rest,
    projectState,
  };
}
